var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as yup from 'yup';
import { useFormik } from 'formik';
import { setLoading, useAppDispatch, useAppSelector } from 'store';
import { getPetVaccinesAPI, updatePetVaccineAPI, updatePetVaccinePhotoAPI } from 'apis';
import { loadAppliedVaccines, loadMandatoryVaccines, loadVaccines } from 'store/dist/slices/PetDocumentation-slice';
import Toast from 'react-native-toast-message';
export var usePetVaccines = function (onNextPress) {
    var dispatch = useAppDispatch();
    var guid = useAppSelector(function (state) { return state.selectedPet; }).guid;
    var actualVaccineUrl = useAppSelector(function (state) { return state.petDocumentation; }).actualVaccineUrl;
    var validationSchema = yup.object().shape({
        vaccineDate: yup.date().required(('La fecha de la vacuna es requerida')),
        vaccineLote: yup.string().required(('Se requiere el numero de lote de la vacuna')),
        vaccineGuid: yup.string().required(('Se requiere el tipo de vacuna')),
        vaccineUrl: yup.string().required(('Se requiere la foto de la vacuna')),
    });
    var values = __rest(useFormik({
        enableReinitialize: true,
        initialValues: {
            vaccineDate: null,
            vaccineLote: '',
            vaccineGuid: '',
            vaccineUrl: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
            var res, resPhoto, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug('FORM VALUES', formValues);
                        return [4 /*yield*/, dispatch(setLoading(true))];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, 6, 8]);
                        return [4 /*yield*/, updatePetVaccineAPI({
                                guid: guid,
                                payload: {
                                    vaccineGUID: formValues.vaccineGuid,
                                    ref: formValues.vaccineLote,
                                    scheduledAt: formValues.vaccineDate.toISOString(),
                                },
                            })];
                    case 3:
                        res = _a.sent();
                        console.debug('UPDATE VACCINE DATE', formValues.vaccineDate.toISOString());
                        console.debug('UPDATE VACCINE RES', res);
                        if (res.success) {
                            Toast.show({
                                text1: res.message || ('Éxito'),
                                text2: res.messageDescription || ('Éxito'),
                                type: res.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: res.message || ('Error'),
                                text2: res.messageDescription || ('Error'),
                                type: res.messageStatus || 'error',
                            });
                        }
                        return [4 /*yield*/, updatePetVaccinePhotoAPI({
                                guid: guid,
                                payload: {
                                    vaccinationGUID: res.data.vaccinationGUID,
                                    file: formValues.vaccineUrl,
                                    vaccineGUID: formValues.vaccineGuid,
                                },
                            })];
                    case 4:
                        resPhoto = _a.sent();
                        console.debug('UPDATE VACCINE PHOTO RES', resPhoto);
                        if (resPhoto.success) {
                            Toast.show({
                                text1: resPhoto.message || ('Éxito'),
                                text2: resPhoto.messageDescription || ('Éxito'),
                                type: resPhoto.messageStatus || 'success',
                            });
                        }
                        else {
                            Toast.show({
                                text1: resPhoto.message || ('Error'),
                                text2: resPhoto.messageDescription || ('Error'),
                                type: resPhoto.messageStatus || 'error',
                            });
                        }
                        return [3 /*break*/, 8];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        Toast.show({
                            text1: ('Error'),
                            text2: e_1.message || ('Error'),
                            type: 'error',
                        });
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, dispatch(setLoading(false))];
                    case 7:
                        _a.sent();
                        onNextPress === null || onNextPress === void 0 ? void 0 : onNextPress(formValues);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }); },
    }), []);
    var getVaccines = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, notAppliedVaccines, appliedVaccines, mandatoryVaccines, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(setLoading(true));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getPetVaccinesAPI({ guid: guid })];
                case 2:
                    res = _a.sent();
                    if (res.success) {
                        notAppliedVaccines = res.data.vaccines.filter(function (v) { return !v.isUpToDate; });
                        dispatch(loadVaccines(notAppliedVaccines));
                        appliedVaccines = res.data.vaccines.filter(function (v) { return v.history.length > 0; });
                        dispatch(loadAppliedVaccines(appliedVaccines));
                        mandatoryVaccines = notAppliedVaccines.filter(function (v) { return v.type === 'MANDATORY'; });
                        dispatch(loadMandatoryVaccines(mandatoryVaccines));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
    return __assign(__assign({}, values), { getVaccines: getVaccines });
};
