import { AppBackground, Button, HeaderWithBack, Input, Switch, Text } from "components";
import React, { useEffect, useMemo, useRef } from "react";
import { KeyboardAvoidingView, Modal, Platform, ScrollView, TouchableOpacity } from "react-native";
import { DateContainer, InputContainer, InputWrapper, MainContainer, ModalContainer, ModalHeader, ModalWrapper, PreviewButtonContainer } from "./styles";
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg'
import { ReactComponent as PawIcon } from '../../../../assets/icons/paw.svg'
import { ReactComponent as XSimpleIcon } from '../../../../assets/icons/x_simple_grey.svg'
import { loadPetChipImage, loadPetPassportImage, useAppDispatch, useAppSelector } from "store";
import { usePetDocumentation } from "business-logic";
import { useNavigate } from "react-router-dom";
import { CamaraCard, ImageTypeSelector } from "../../components/CameraCard";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css"

const Documents: React.FC = () => {

  const chipUrl = useAppSelector((state) => state.petDocumentation.chip.url)
  const passportUrl = useAppSelector((state) => state.petDocumentation.passport.url)

  const [isChip, setIsChip] = React.useState<boolean>(true)
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [imageError, setImageError] = React.useState<string>('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    getPetDocumentDetails,
    setFieldValue,
    getPetChipDetails,
    handleSubmit,
    validateForm,
  } = usePetDocumentation()

  const onBackPress = () => {
    navigate('/services/passport')
  }

  const onSwitch = () => {
    setIsChip((prev) => !prev)
  }

  const handleDateChange = (date: Date | null) => {
    setFieldValue('passportExpirationDate', date)
  }

  useEffect(() => {
    getPetDocumentDetails()
    getPetChipDetails()
  }, [])

  // useEffect(() => {
  //   setFieldValue('passportUrl', passportUrl)
  // }, [passportUrl, setFieldValue])

  // useEffect(() => {
  //   setFieldValue('chipUrl', chipUrl)
  // }, [chipUrl, setFieldValue])

  const inputsChip: any = useMemo(() => {
    return [
      {
        title: ('Número de chip'),
        value: values.chipNumber,
        onChangeText: handleChange('chipNumber'),
        onBlur: handleBlur('chipNumber'),
        placeholder: ('Número de chip'),
        error: errors.chipNumber,
      },
      {
        title: ('Nombre y Apellidos del tutor legal'),
        value: values.ownerName,
        onChangeText: handleChange('ownerName'),
        onBlur: handleBlur('ownerName'),
        placeholder: ('DNI'),
        error: errors.ownerName,
      },
      {
        title: ('Nombre y Apellidos'),
        value: values.ownerDNI,
        onChangeText: handleChange('ownerDNI'),
        onBlur: handleBlur('ownerDNI'),
        placeholder: ('DNI'),
        error: errors.ownerDNI,
      },
    ]
  }, [values, errors])

  const onGalleryPress = (file: File, preview: string, type: ImageTypeSelector) => {
    if (type === ImageTypeSelector.CHIP) {
      setFieldValue('chipUrl', file)
      dispatch(loadPetChipImage(preview))
    } else {
      setFieldValue('passportUrl', file)
      dispatch(loadPetPassportImage(preview))
    }
  }

  const handleUpdatePress = async () => {
    const errors = await validateForm()
    if (isChip && !chipUrl) {
      setImageError(('Por favor sube una imagen del chip'))
      return
    }
    if (Object.keys(errors).length === 0){
      handleSubmit()
      navigate('/services/passport')
    } 
  }

  useEffect(() => {
    if (chipUrl) {
      setImageError('')
    }
  }, [chipUrl])

  const handlePreviewPress = () => {
    setShowModal(!showModal)
  }

  return (
    <AppBackground isSafe>
      <HeaderWithBack title={('Documentación')} backPress={onBackPress} backIcon={<BackIcon />} />
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={{ flex: 1 , padding: 10}}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 10 : 40}
      >
        <ScrollView
          style={{ flex: 1, marginBottom: 10 }}
        >
          <Switch
            option1={('Chip')}
            option2={('Pasaporte \n (opcional)')}
            onPress={onSwitch}
            isFirst={isChip}
          />
          <MainContainer>
            {isChip ? (
              <>
              <InputContainer>
                <CamaraCard
                  title={('Elige una foto')}
                  subtitle={('Sube una foto FRONTAL del chip')}
                  onGalleryPress={onGalleryPress}
                  url={chipUrl}
                  type={ImageTypeSelector.CHIP}
                />
                {!!imageError && (
                  <Text type="small" color="error">
                    {imageError}
                  </Text>
                )}
              </InputContainer>
                {chipUrl ? (
                  <PreviewButtonContainer>
                    <Button
                      title={('Vista previa')}
                      icon={<PawIcon width={24} height={24} />}
                      size="block"
                      textType="cta_medium"
                      borderRadius={5}
                      textColor="background0"
                      allowIconAndText
                      color="primary"
                      onPress={handlePreviewPress}
                    />
                  </PreviewButtonContainer>
                ) : (
                  <></>
                )}
                {inputsChip?.map((input: any, index: number) => (
                  <InputWrapper key={`${input.placeholder}-${index}`}>
                    <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                      {input.title}
                    </Text>
                    <InputContainer>
                      <Input
                        inputProps={{
                          value: input.value,
                          onChangeText: input.onChangeText,
                          onBlur: input.onBlur,
                          placeholder: input.placeholder,
                        }}
                      />
                      {input.error && (
                        <Text type="small" color="error">
                          {input.error.toString()}
                        </Text>
                      )}
                    </InputContainer>
                  </InputWrapper>
                ))}
              </>
            ) : (
              <>
                <CamaraCard
                  title={('Elige una foto')}
                  subtitle={('Sube una foto FRONTAL del pasaporte o cartilla de tu mascota')}
                  onGalleryPress={onGalleryPress}
                  url={passportUrl}
                  type={ImageTypeSelector.PASSPORT}
                />
                {passportUrl ? (
                  <PreviewButtonContainer>
                    <Button
                      title={('Vista previa')}
                      icon={<PawIcon width={24} height={24} />}
                      size="block"
                      textType="cta_medium"
                      borderRadius={5}
                      textColor="background0"
                      allowIconAndText
                      color="primary"
                      onPress={handlePreviewPress}
                    />
                  </PreviewButtonContainer>
                ) : (
                  <></>
                )}
                <DateContainer>
                  <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                    {('Seleccionar la fecha')}
                  </Text>
                  <DatePicker
                    selected={values.passportExpirationDate}
                    date={values.passportExpirationDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy/MM/dd"
                    locale="es"
                    placeholderText="Seleccionar fecha"
                  />
                </DateContainer>
                <InputWrapper>
                  <Text type="H3" color="foreground0" fontWeight="700" textAlign="center">
                    {('Número de pasaporte')}
                  </Text>
                  <InputContainer>
                    <Input
                      inputProps={{
                        value: values.passportNumber,
                        onChangeText: handleChange('passportNumber'),
                        onBlur: handleBlur('passportNumber'),
                        placeholder: ('Numero de pasaporte'),
                      }}
                    />
                    {errors.passportNumber && (
                      <Text type="small" color="error">
                        {errors.passportNumber.toString()}
                      </Text>
                    )}
                  </InputContainer>
                </InputWrapper>
              </>
            )}
          </MainContainer>
        </ScrollView>
        <Button
          title={('Actualizar')}
          icon={<PawIcon width={24} height={24} />}
          size="block"
          textType="cta_medium"
          borderRadius={5}
          textColor="background0"
          allowIconAndText
          color="primary"
          onPress={handleUpdatePress}
        />
      </KeyboardAvoidingView>
      <Modal
        visible={showModal}
        transparent={true}
        onRequestClose={handlePreviewPress}
      >
        <ModalWrapper>
          <ModalContainer>
            <ModalHeader>
              <Text type="H5">{('petDocumentation.vaccine.preview')}</Text>
              <TouchableOpacity onPress={handlePreviewPress}>
                <XSimpleIcon width={30} height={30} />
              </TouchableOpacity>
            </ModalHeader>
            <img src={isChip ? chipUrl : passportUrl} style={{ width: 300, height: 300 }} />
          </ModalContainer>
        </ModalWrapper>
      </Modal>
    </AppBackground>
  );
};

export default Documents;